export default {
    getRecommendations(state) {
        let recommendations = []

        state.homeData.selected_recommendations.forEach(recommendation => {
            switch (recommendation.type) {
                case 'Location':
                    const location = state.locations.find(location => location.id == recommendation.id)
                    if (location) {
                        recommendations.push(location)
                    }
                    break
                case 'Place':
                    const place = state.places.find(place => place.id == recommendation.id)
                    if (place) {
                        recommendations.push(place)
                    }
                    break
                default:
                    break
            }
        })
        
        return recommendations
    },
    getSelectedCategories(state) {
        return state.homeData.selected_categories
    },
    getCategories(state) {
        return state.categories
    },
    getCategoryNameByCategory(state) {
        return (categoryId) => {
            const category = state.categories.filter(category => category.id == categoryId)[0]

            if (category) return category.name
            else return {}
        }
    },
    getLocationsByCategory(state) {
        return (categoryId) => {
            let filteredData = []

            if (categoryId) {
                state.locations.filter(location => location.categories.includes(categoryId)).forEach(el => {
                    filteredData.push(el)
                })
            }
            
            return filteredData
        }
    },
    getNearbyLocations(state) {
        return (placeId) => {
            const filteredData = state.nearbyLocations.filter(location => location.entity_from.id == placeId)

            if (filteredData) {
                return filteredData.map(relation => state.getLocationById(relation.entity_to.id)).filter(item => item)
            } else {
                return []
            }
        }
    },
    getSearchResults(state) {
        return (search_value, language = 'en') => {
            let search_results = []

            state.events.filter(
                event => event.name[language].toLowerCase().includes(search_value.toLowerCase())
            ).forEach(el => {
                if (el && Object.keys(el).length) {
                    search_results.push(el)
                }
            })

            state.places.filter(
                place => place.name[language].toLowerCase().includes(search_value.toLowerCase())
            ).forEach(el => {
                if (el && Object.keys(el).length) {
                    search_results.push(el)
                }
            })

            state.locations.filter(
                location => location.name[language].toLowerCase().includes(search_value.toLowerCase()) ||
                (state.places.find(place => place.id == location.parent_place_id)?.name[language] &&
                state.places.find(place => place.id == location.parent_place_id).name[language].toLowerCase().includes(search_value.toLowerCase()))
            ).forEach(el => {
                if (el && Object.keys(el).length) {
                    search_results.push(el)
                }
            })

            return search_results
        }
    },
    getPlaces(state) {
        return state.places
    },
    getPlacesSortedNameAscending(state) {
        return (lang = 'en') => {
            let placesSorted = state.places.toSorted((a, b) => {
                const nameA = a.name[lang].toUpperCase()
                const nameB = b.name[lang].toUpperCase()

                return new Intl.Collator(lang).compare(nameA, nameB)
            })

            return placesSorted
        }
    },
    getPlacesSortedNameDescending(state) {
        return (lang = 'en') => {
            let placesSorted = state.places.toSorted((a, b) => {
                const nameA = a.name[lang].toUpperCase()
                const nameB = b.name[lang].toUpperCase()

                return new Intl.Collator(lang).compare(nameA, nameB)
            })

            return placesSorted.reverse()
        }
    },
    getLocationsForPlace(state) {
        return (placeId) => {
            let locations = []

            if (placeId) {
                locations = state.locations.filter(location => location.parent_place_id == placeId)
            }

            return locations
        }
    },
    getPlaceById(state) {
        return (placeId) => {
            let place = null

            if (placeId) {
                place = state.places.find(place => place.id == placeId)
            }
            
            return place
        }
    },
    getLocations(state) {
        return ({categoryFilter = null}) => {
            let data = []

            if (categoryFilter) {
                data.push(...state.locations.filter(
                    location => location.categories.includes(categoryFilter)
                ))
            } else {
                data.push(...state.locations)
            }

            return data
        }
    },
    getFilterSortData(state) {
        return ({sortType = 'init', categoryIds = [], language = 'en'}) => {
            let data = []

            if (categoryIds.length) {
                data.push(...state.locations.filter(
                    location => {
                        return categoryIds.every(categoryId => location.categories.includes(categoryId))
                    }
                ))
            } else {
                data.push(...state.locations)
            }

            switch (sortType) {
                case 'sortAsc':
                    data = data.toSorted((a, b) => {
                        const nameA = a.name[language].toUpperCase()
                        const nameB = b.name[language].toUpperCase()
        
                        return new Intl.Collator(language).compare(nameA, nameB)
                    })
                    break;
                case 'sortDesc':
                    data = data.toSorted((a, b) => {
                        const nameA = a.name[language].toUpperCase()
                        const nameB = b.name[language].toUpperCase()
        
                        return new Intl.Collator(language).compare(nameA, nameB)
                    }).reverse()
                    break;
                default:
                    data = data.toSorted((a, b) => {
                        if (a.id < b.id) return -1
                        if (a.id > b.id) return 1
                        return 0
                    })
                    break;
            }

            return data
        }
    },
    getLocationById(state) {
        return (locationId) => {
            let location = null

            if (locationId) {
                location = state.locations.find(location => location.id == locationId)
            }
            
            return location
        }
    },
    getCategoryById(state) {
        return (categoryId) => {
            let category = null

            if (categoryId) {
                category = state.categories.find(category => category.id == categoryId)
            }

            return category
        }
    },
    getEventById(state) {
        return (eventId) => {
            let event = null

            if (eventId) {
                event = state.events.find(event => event.id == eventId)
            }

            return event
        }
    },
    getEventsByParentPlaceId(state) {
        return (parentPlaceId) => {
            let events = []

            if (parentPlaceId) {
                events = state.events.filter(event => event.parent_place_id == parentPlaceId)
            }

            return events
        }
    },
    getEventsByParentLocationId(state) {
        return (parentLocationId) => {
            let events = []

            if (parentLocationId) {
                events = state.events.filter(event => event.parent_location_id == parentLocationId)
            }

            return events
        }
    },
    getEvents(state) {
        return ({init = true, all = false}) => {
            if (all) {
                return state.events
            } else {
                if (init) {
                    return state.events.slice(0, 9)
                } else {
                    return state.events.slice(9)
                }
            }
        }
    },
    getCurrentEvents(state) {
        const now = new Date()
        let filteredData = state.events.filter(event => {
            const dateStartUnix = event.start_date || 0
            const dateEndUnix = event.end_date || 0

            const dateStart = new Date(dateStartUnix * 1000)
            const dateEnd = new Date(dateEndUnix * 1000)

            return dateStart <= now && dateEnd >= now
        })

        return filteredData
    },
    getUpcomingEvents(state) {
        const now = new Date()
        let filteredData = state.events.filter(event => {
            const dateStartUnix = event.start_date || 0

            const dateStart = new Date(dateStartUnix * 1000)

            return dateStart >= now
        })

        return filteredData
    },
    getMapData(state) {
        return (categoryIds = []) => {
            let mapData = []

            if (categoryIds.length) {
                mapData.push(...state.locations.filter(
                    location =>  categoryIds.every(categoryId => location.categories.includes(categoryId))
                ).map(mapItem => {
                    return {
                        ...mapItem,
                        pictograms: state.getPictogramsByIdArray(mapItem.pictograms),
                        parent_place: state.getPlaceById(mapItem.parent_place_id)
                    }
                }))
            } else {
                mapData.push(...state.locations.map(location => {
                    return {
                        ...location,
                        pictograms: state.getPictogramsByIdArray(location.pictograms),
                        parent_place: state.getPlaceById(location.parent_place_id)
                    }
                }))
            }

            mapData.push(...state.events.map(event => {
                return {
                    ...event,
                    pictograms: state.getPictogramsByIdArray(event.pictograms),
                    parent_place: state.getPlaceById(event.parent_place_id)
                }
            }))

            return mapData
        }
    },
    getPictograms(state) {
        return state.pictograms
    },
    getPictogramsByIdArray(state) {
        return (pictogramIdArray) => {
            let pictograms = []

            if (pictogramIdArray) {
                pictograms = state.pictograms.filter(pictogram => pictogramIdArray.indexOf(pictogram.id) >= 0)
            }
            
            return pictograms
        }
    },
    isHomeDataLoaded(state) {
        return state.homeDataLoaded
    },
    isCategoriesLoaded(state) {
        return state.categoriesLoaded
    },
    isEventsLoaded(state) {
        return state.eventsLoaded
    },
    isPlacesLoaded(state) {
        return state.placesLoaded
    },
    isLocationsLoaded(state) {
        return state.locationsLoaded
    },
    isNearbyLocationsLoaded(state) {
        return state.nearbyLocationsLoaded
    },
    isPictogramsLoaded(state) {
        return state.pictogramsLoaded
    },
    storeCompletelyLoaded(state) {
        return state.homeDataLoaded &&
            state.categoriesLoaded &&
            state.eventsLoaded &&
            state.placesLoaded &&
            state.locationsLoaded &&
            state.nearbyLocationsLoaded &&
            state.pictogramsLoaded
    }
}