export default {
    setHomeData(data = {}) {
        if (data.selected_recommendations && data.selected_recommendations.length) {
            this.homeData.selected_recommendations.push(...data.selected_recommendations)
        }
        if (data.selected_events && data.selected_events.length) {
            this.homeData.selected_events.push(...data.selected_events)
        }
        if (data.selected_categories && data.selected_categories.length) {
            this.homeData.selected_categories.push(...data.selected_categories)
        }
    },
    setHomeDataAsLoaded() {
        this.homeDataLoaded = true
    },

    addCategories(data = []) {
        data.forEach(category => {
            this.categories.push(category)
        })
    },
    addCategory(data) {
        this.categories.push(data)
    },
    setCategoriesAsLoaded() {
        this.categoriesLoaded = true
    },

    addEvents(data = []) {
        data.forEach(event => {
            this.events.push({
                ...event,
                type: 'event'
            })
        })
    },
    addEvent(data) {
        this.events.push({
            ...data,
            type: 'event'
        })
    },
    setEventsAsLoaded() {
        this.eventsLoaded = true
    },

    addPlaces(data = []) {
        data.forEach(place => {
            this.places.push({
                ...place,
                type: 'place'
            })
        })
    },
    addPlace(data) {
        this.places.push({
            ...data,
            type: 'place'
        })
    },
    setPlacesAsLoaded() {
        this.placesLoaded = true
    },

    addLocations(data) {
        data.forEach(location => {
            this.locations.push({
                ...location,
                type: 'location'
            })
        })
    },
    addLocation(data) {
        this.locations.push({
            ...data,
            type: 'location'
        })
    },
    setLocationsAsLoaded() {
        this.locationsLoaded = true
    },

    addNearbyLocations(data) {
        data.forEach(location => {
            this.nearbyLocations.push({
                ...location,
                type: 'location'
            })
        })
    },
    addNearbyLocation(data) {
        this.nearbyLocations.push({
            ...data,
            type: 'location'
        })
    },
    setNearbyLocationsAsLoaded() {
        this.nearbyLocationsLoaded = true
    },

    addPictograms(data) {
        this.pictograms.push(...data)
    },
    setPictogramsAsLoaded() {
        this.pictogramsLoaded = true
    }
}