import { default as aboutJCQTnN45atMeta } from "/var/www/webovi/explore-lika.com/pages/about.vue?macro=true";
import { default as eeaf1k4yGra9vMeta } from "/var/www/webovi/explore-lika.com/pages/eea.vue?macro=true";
import { default as _91eventId_93psrlLSiwySMeta } from "/var/www/webovi/explore-lika.com/pages/event/[eventId].vue?macro=true";
import { default as events1zYdNlxlxfMeta } from "/var/www/webovi/explore-lika.com/pages/events.vue?macro=true";
import { default as index6JY0JWyxrcMeta } from "/var/www/webovi/explore-lika.com/pages/index.vue?macro=true";
import { default as _91locationId_93dxekxdAT6UMeta } from "/var/www/webovi/explore-lika.com/pages/location/[locationId].vue?macro=true";
import { default as locationsMD0G2k8N3eMeta } from "/var/www/webovi/explore-lika.com/pages/locations.vue?macro=true";
import { default as mapkFXrdxpbsJMeta } from "/var/www/webovi/explore-lika.com/pages/map.vue?macro=true";
import { default as _91placeId_93nX8rOiMdWPMeta } from "/var/www/webovi/explore-lika.com/pages/place/[placeId].vue?macro=true";
import { default as placesYS24mKyspJMeta } from "/var/www/webovi/explore-lika.com/pages/places.vue?macro=true";
import { default as privacy_45policyM1LY1M4EyBMeta } from "/var/www/webovi/explore-lika.com/pages/privacy-policy.vue?macro=true";
import { default as searchyjDt06qIOPMeta } from "/var/www/webovi/explore-lika.com/pages/search.vue?macro=true";
import { default as terms_45of_45useTky32QFrznMeta } from "/var/www/webovi/explore-lika.com/pages/terms-of-use.vue?macro=true";
export default [
  {
    name: aboutJCQTnN45atMeta?.name ?? "about",
    path: aboutJCQTnN45atMeta?.path ?? "/about",
    meta: aboutJCQTnN45atMeta || {},
    alias: aboutJCQTnN45atMeta?.alias || [],
    redirect: aboutJCQTnN45atMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/about.vue").then(m => m.default || m)
  },
  {
    name: eeaf1k4yGra9vMeta?.name ?? "eea",
    path: eeaf1k4yGra9vMeta?.path ?? "/eea",
    meta: eeaf1k4yGra9vMeta || {},
    alias: eeaf1k4yGra9vMeta?.alias || [],
    redirect: eeaf1k4yGra9vMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/eea.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93psrlLSiwySMeta?.name ?? "event-eventId",
    path: _91eventId_93psrlLSiwySMeta?.path ?? "/event/:eventId()",
    meta: _91eventId_93psrlLSiwySMeta || {},
    alias: _91eventId_93psrlLSiwySMeta?.alias || [],
    redirect: _91eventId_93psrlLSiwySMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/event/[eventId].vue").then(m => m.default || m)
  },
  {
    name: events1zYdNlxlxfMeta?.name ?? "events",
    path: events1zYdNlxlxfMeta?.path ?? "/events",
    meta: events1zYdNlxlxfMeta || {},
    alias: events1zYdNlxlxfMeta?.alias || [],
    redirect: events1zYdNlxlxfMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/events.vue").then(m => m.default || m)
  },
  {
    name: index6JY0JWyxrcMeta?.name ?? "index",
    path: index6JY0JWyxrcMeta?.path ?? "/",
    meta: index6JY0JWyxrcMeta || {},
    alias: index6JY0JWyxrcMeta?.alias || [],
    redirect: index6JY0JWyxrcMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91locationId_93dxekxdAT6UMeta?.name ?? "location-locationId",
    path: _91locationId_93dxekxdAT6UMeta?.path ?? "/location/:locationId()",
    meta: _91locationId_93dxekxdAT6UMeta || {},
    alias: _91locationId_93dxekxdAT6UMeta?.alias || [],
    redirect: _91locationId_93dxekxdAT6UMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/location/[locationId].vue").then(m => m.default || m)
  },
  {
    name: locationsMD0G2k8N3eMeta?.name ?? "locations",
    path: locationsMD0G2k8N3eMeta?.path ?? "/locations",
    meta: locationsMD0G2k8N3eMeta || {},
    alias: locationsMD0G2k8N3eMeta?.alias || [],
    redirect: locationsMD0G2k8N3eMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: mapkFXrdxpbsJMeta?.name ?? "map",
    path: mapkFXrdxpbsJMeta?.path ?? "/map",
    meta: mapkFXrdxpbsJMeta || {},
    alias: mapkFXrdxpbsJMeta?.alias || [],
    redirect: mapkFXrdxpbsJMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/map.vue").then(m => m.default || m)
  },
  {
    name: _91placeId_93nX8rOiMdWPMeta?.name ?? "place-placeId",
    path: _91placeId_93nX8rOiMdWPMeta?.path ?? "/place/:placeId()",
    meta: _91placeId_93nX8rOiMdWPMeta || {},
    alias: _91placeId_93nX8rOiMdWPMeta?.alias || [],
    redirect: _91placeId_93nX8rOiMdWPMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/place/[placeId].vue").then(m => m.default || m)
  },
  {
    name: placesYS24mKyspJMeta?.name ?? "places",
    path: placesYS24mKyspJMeta?.path ?? "/places",
    meta: placesYS24mKyspJMeta || {},
    alias: placesYS24mKyspJMeta?.alias || [],
    redirect: placesYS24mKyspJMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/places.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyM1LY1M4EyBMeta?.name ?? "privacy-policy",
    path: privacy_45policyM1LY1M4EyBMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyM1LY1M4EyBMeta || {},
    alias: privacy_45policyM1LY1M4EyBMeta?.alias || [],
    redirect: privacy_45policyM1LY1M4EyBMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: searchyjDt06qIOPMeta?.name ?? "search",
    path: searchyjDt06qIOPMeta?.path ?? "/search",
    meta: searchyjDt06qIOPMeta || {},
    alias: searchyjDt06qIOPMeta?.alias || [],
    redirect: searchyjDt06qIOPMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useTky32QFrznMeta?.name ?? "terms-of-use",
    path: terms_45of_45useTky32QFrznMeta?.path ?? "/terms-of-use",
    meta: terms_45of_45useTky32QFrznMeta || {},
    alias: terms_45of_45useTky32QFrznMeta?.alias || [],
    redirect: terms_45of_45useTky32QFrznMeta?.redirect,
    component: () => import("/var/www/webovi/explore-lika.com/pages/terms-of-use.vue").then(m => m.default || m)
  }
]