<template>
    <div
        class="aselect select language-select"
        :class="{ 'visible-dropdown': visible }"
        :data-value="locale"
        @click="toggle"
        v-click-outside="closeDropdown"
    >
        <div class="selector">
            <div class="label">
                <span>{{ selectedLang.name }}</span>
            </div>
            <span class="selector-actions">
                <span class="icon arrow">
                    <i class="icon-31"></i>
                </span>
            </span>
            <div class="aselect-dropdown-wrapper" :class="{ hidden : !visible, visible }">
                <ul class="aselect-dropdown">
                    <li :class="{ current : item.code === locale }" v-for="item in availableLocales" @click="selectItem(item)">{{ item.name }}</li>
                </ul>
            </div>
        </div>
    </div>
    <select class="d-none" v-model="locale" @change="setLocale" ref="select">
        <option v-for="locale in availableLocales" :key="locale.code" :value="locale.code">
            {{ locale.name }}
        </option>
    </select>
</template>

<script setup>
    import { useLanguageStore } from '~/store'

    const store = useLanguageStore()
    const select = ref()
    const { locale, locales, setLocaleCookie } = useI18n()
    const availableLocales = ref(computed(() => {
        return (locales.value)
    }))
    const selectedLang = ref((locales.value).find(i => i.code == locale.value))
    const selectedValue = ref('')
    const visible = ref(false)

    function toggle() {
        visible.value = !visible.value
    }

    function selectItem(option) {
        if (option.code == locale.value) {
            return
        }
        selectedValue.value = option.code
        selectedLang.value = option
        select.value.value = option.code
        select.value.dispatchEvent(new Event('change'), {option})
    }

    async function setLocale(event) {
        await setLocaleCookie(event.target.value)
        store.$patch({ currentLanguage: event.target.value })
    }

    function closeDropdown() {
        if (visible.value) visible.value = false
    }

    const vClickOutside = {
        mounted(el, binding) {
            el.__ClickOutsideHandler__ = event => {
                if (!(el == event.target || el.contains(event.target))) {
                    binding.value(event)
                }
            }
            document.body.addEventListener('click', el.__ClickOutsideHandler__)
        },
        unmounted(el) {
            document.body.removeEventListener('click', el.__ClickOutsideHandler__)
        }
    }
</script>