<template>
  	<div class="main-wrapper">
		<Header />
		<NuxtPage>
			<router-view></router-view>
		</NuxtPage>
		<Footer />
		<ScrollToTop />
  	</div>
</template>

<script setup>
	import { ref } from 'vue'
	import Header from '~/components/header/Header.vue'
	import Footer from '~/components/footer/Footer.vue'
	import ScrollToTop from '~/components/footer/ScrollToTop.vue'
	import AOS from 'aos'
	import { useMainStore } from '~/store'

	onMounted(() => {
		AOS.init();
	})

	const store = useMainStore()
	await storeHomeData()
	await storeCategories()
	await storeEvents()
	await storePlaces()
	await storeLocations()
	await storeNearbyLocations()
	await storePictograms()

	async function storeHomeData() {
		if (store.homeDataLoaded) {
			return
		}
		const { data } = await useFetch('/api/v1/home/sync')
		const homeResponse = data.value
		if (homeResponse.errors) {
			console.error(homeResponse.errors)
			throw new Error('Error occured')
		}
		if (homeResponse.data) {
			store.setHomeData(homeResponse.data.updateOrCreate[0])
			store.setHomeDataAsLoaded()
		}
	}

	async function storeCategories(page = 1, categoriesArr = [], per_page = 100) {
		if (store.categoriesLoaded) {
			return
		}
		let categoriesData = categoriesArr
		const { data } = await useFetch('/api/v1/category/sync', {
			params: {
				timestamp: 0,
				page,
				per_page
			},
		})
		const categoriesResponse = data.value
		if (categoriesResponse.errors) {
			console.error(categoriesResponse.errors)
			throw new Error('Error occured')
		}
		if (categoriesResponse.data) {
			categoriesData.push(...categoriesResponse.data.updateOrCreate)
			if (categoriesResponse.pagination.current_page < categoriesResponse.pagination.total_pages) {
				await storeCategories(categoriesResponse.pagination.current_page + 1, categoriesData)
			} else {
				store.addCategories(categoriesData)
				store.setCategoriesAsLoaded()
			}
		}
	}

	async function storeEvents(page = 1, eventsArr = [], per_page = 100) {
		if (store.eventsLoaded) {
			return
		}
		let eventsData = eventsArr
		const { data } = await useFetch('/api/v1/event/sync', {
			params: {
				timestamp: 0,
				page,
				per_page
			},
		})
		const eventsResponse = data.value
		if (eventsResponse.errors) {
			console.error(eventsResponse.errors)
			throw new Error('Error occured')
		}
		if (eventsResponse.data) {
			eventsData.push(...eventsResponse.data.updateOrCreate)
			if (eventsResponse.pagination.current_page < eventsResponse.pagination.total_pages) {
				await storeEvents(eventsResponse.pagination.current_page + 1, eventsData)
			} else {
				store.addEvents(eventsData)
				store.setEventsAsLoaded()
			}
		}
	}

	async function storePlaces(page = 1, placesArr = [], per_page = 100) {
		if (store.placesLoaded) {
			return
		}
		let placesData = placesArr
		const { data } = await useFetch('/api/v1/place/sync', {
			params: {
				timestamp: 0,
				page,
				per_page
			},
		})
		const placesResponse = data.value
		if (placesResponse.errors) {
			console.error(placesResponse.errors)
			throw new Error('Error occured')
		}
		if (placesResponse.data) {
			placesData.push(...placesResponse.data.updateOrCreate)
			if (placesResponse.pagination.current_page < placesResponse.pagination.total_pages) {
				await storePlaces(placesResponse.pagination.current_page + 1, placesData)
			} else {
				store.addPlaces(placesData)
				store.setPlacesAsLoaded()
			}
		}
	}

	async function storeLocations(page = 1, locationsArr = [], per_page = 100) {
		if (store.locationsLoaded) {
			return
		}
		let locationsData = locationsArr
		const { data } = await useFetch('/api/v1/location/sync', {
			params: {
				timestamp: 0,
				page,
				per_page
			},
		})
		const locationsResponse = data.value
		if (locationsResponse.errors) {
			console.error(locationsResponse.errors)
			throw new Error('Error occured')
		}
		if (locationsResponse.data) {
			locationsData.push(...locationsResponse.data.updateOrCreate)
			if (locationsResponse.pagination.current_page < locationsResponse.pagination.total_pages) {
				await storeLocations(locationsResponse.pagination.current_page + 1, locationsData)
			} else {
				store.addLocations(locationsData)
				store.setLocationsAsLoaded()
			}
		}
	}

	async function storeNearbyLocations(page = 1, nearbyLocationsArr = [], per_page = 100) {
		if (store.nearbyLocationsLoaded) {
			return
		}
		let nearbyLocationsData = nearbyLocationsArr
		const { data } = await useFetch('/api/v1/nearby-location/sync', {
			params: {
				timestamp: 0,
				page,
				per_page
			},
		})
		const nearbyLocationsResponse = data.value
		if (nearbyLocationsResponse.errors) {
			console.error(nearbyLocationsResponse.errors)
			throw new Error('Error occured')
		}
		if (nearbyLocationsResponse.data) {
			nearbyLocationsData.push(...nearbyLocationsResponse.data.updateOrCreate)
			if (nearbyLocationsResponse.pagination.current_page < nearbyLocationsResponse.pagination.total_pages) {
				await storeNearbyLocations(nearbyLocationsResponse.pagination.current_page + 1, nearbyLocationsData)
			} else {
				store.addNearbyLocations(nearbyLocationsData)
				store.setNearbyLocationsAsLoaded()
			}
		}
	}

	async function storePictograms(page = 1, pictogramsArr = [], per_page = 100) {
		if (store.pictogramsLoaded) {
			return
		}
		let pictogramsData = pictogramsArr
		const { data } = await useFetch('/api/v1/facility-pictogram/sync', {
			params: {
				timestamp: 0,
				page,
				per_page
			},
		})
		const pictogramsResponse = data.value
		if (pictogramsResponse.errors) {
			console.error(pictogramsResponse.errors)
			throw new Error('Error occured')
		}
		if (pictogramsResponse.data) {
			pictogramsData.push(...pictogramsResponse.data.updateOrCreate)
			if (pictogramsResponse.pagination.current_page < pictogramsResponse.pagination.total_pages) {
				await storePictograms(pictogramsResponse.pagination.current_page + 1, pictogramsData)
			} else {
				store.addPictograms(pictogramsData)
				store.setPictogramsAsLoaded()
			}
		}
	}
</script>