import { defineStore } from 'pinia'
// import mutations from './mutations'
import getters from './mainStore/getters'
import actions from './actions'

export const useMainStore = defineStore('main', {
	state: () => ({
		homeData: {
			selected_recommendations: [],
			selected_events: [],
			selected_categories: []
		},
		homeDataLoaded: false,
		categories: [],
		categoriesLoaded: false,
		events: [],
		currentEvents: [],
		eventsLoaded: false,
		places: [],
		placesLoaded: false,
		locations: [],
		locationsLoaded: false,
		nearbyLocations: [],
		nearbyLocationsLoaded: false,
		pictograms: [],
		pictogramsLoaded: false
	}),
	// mutations,
	actions,
	getters
})

export const useLanguageStore = defineStore('language_store', {
	state: () => ({
		currentLanguage: '',
	}),
	getters: {
		
	}
})