<template>
    <div id="edu-search-popup" class="edu-search-popup">
        <div class="content-wrap">
            <div class="site-logo">
                <NuxtLink to="/">
                    <img class="logo-light" src="/images/logo/explore-lika-logo-main.svg" alt="Explore lika logo">
                    <img class="logo-dark" src="/images/logo/explore-lika-logo-main-light.svg" alt="Explore lika logo light">
                </NuxtLink>
            </div>
            <div class="inner">
                <form class="search-form" @submit.prevent="submit">
                    <input type="text" class="edublink-search-popup-field" ref="searchInput" :placeholder="$t('search.search_here')" v-model="search_value">
                    <div class="action-buttons">
                        <button type="button" class="submit-button clear-input me-3" v-if="search_value" @click="clearInput"><i class="icon-73"></i></button>
                        <button class="submit-button"><i class="icon-2"></i></button>
                    </div>
                </form>
            </div>
            <div class="close-button">
                <button class="close-trigger" @click="searchPopUpClose()"><i class="icon-73"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'

    export default {
        setup() {
            return {
                searchInput: ref(null),
                search_value: ref(null)
            }
        },

        data() {
            const search_value = this.$route.query.search_value ? this.$route.query.search_value : ''
            this.search_value = search_value

            return {}
        },

        watch: {
            '$route' (to, from) {
                const search_value = this.$route.query.search_value ? this.$route.query.search_value : ''
                this.search_value = search_value ? search_value : null
                if (this.searchInput) {
                    this.searchInput.value = search_value
                }
                this.searchPopUpClose()
            }
        },

        methods: {
            clearInput() {
                if (this.searchInput) {
                    this.searchInput.value = ''
                    this.search_value = null
                }
            },
            async submit() {
                if (this.search_value) {
                    this.searchPopUpClose()
                    await navigateTo({
                        path: '/search',
                        query: {
                            search_value: this.search_value
                        }
                    })
                }
            },
            // SearchPopUp Close
            searchPopUpClose() {
                const el = document.querySelector('#edu-search-popup')
                el.classList.remove('open')
            }
        }
    }
</script>