<template>
    <header class="edu-header header-style-2">
        <div class="header-mainmenu" :class="{ 'edu-sticky': isSticky }">
            <div class="container">
                <div class="header-navbar">
                    <div class="header-brand">
                        <div class="logo">
                            <NuxtLink to="/">
                                <img class="logo-light" src="/images/logo/explore-lika-logo-main.svg" alt="Explore lika logo">
                                <img class="logo-dark" src="/images/logo/explore-lika-logo-main-light.svg" alt="Explore lika logo light">
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="header-mainnav">
                        <nav class="mainmenu-nav">
                            <HeaderNavigation />
                        </nav>
                    </div>
                    <div class="header-right">
                        <ul class="header-action">
                            <li class="icon search-icon">
                                <button class="search-trigger" @click="searchPopUpOpen('addClass', 'open')">
                                    <i class="icon-2"></i>
                                </button>
                            </li>
                            <li class="language-switch">
                                <CustomComponentsLanguageSelect />
                            </li>
                            <li class="mobile-menu-bar d-block d-xl-none">
                                <button class="hamberger-button" @click="mobileMenuOpen('addClass', 'active')">
                                    <i class="icon-54"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <HeaderSearchPopUp />

        <HeaderOffCanvasMobileMenu />
    </header>
</template>

<script>
    import { ref } from 'vue'

    export default {
        data() {
            return {
                isSticky: false,
            }
        },

        mounted() {
            window.addEventListener('scroll', () => {
                let scrollPos = window.scrollY
                if (scrollPos >= 200) {
                    this.isSticky = true
                } else {
                    this.isSticky = false
                }
            })
        },

        methods: {
            // Off-canvas Mobile Menu Open
            mobileMenuOpen(addRemoveClass, className) {
                const el = document.querySelector('#offcanvas-menu');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },

            // search popup
            searchPopUpOpen(addRemoveClass, className) {
                const el = document.querySelector('#edu-search-popup');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            }
        }
    }
</script>