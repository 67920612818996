<template>
    <ul class="edublink-mobile-menu mainmenu">
        <li>
            <NuxtLink to="/">{{ $t('main_links.home') }}</NuxtLink>
        </li>
        <li>
            <NuxtLink to="/locations">{{ $t('main_links.locations') }}</NuxtLink>
        </li>
        <li>
            <NuxtLink to="/places">{{ $t('main_links.places') }}</NuxtLink>
        </li>
        <li>
            <NuxtLink to="/events">{{ $t('main_links.events') }}</NuxtLink>
        </li>
        <li>
            <NuxtLink to="/map">{{ $t('main_links.map') }}</NuxtLink>
        </li>
    </ul>
</template>