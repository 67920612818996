<template>
    <svg width="135" height="40" viewBox="0 0 135 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M81.5259 19.2013V21.4924H80.0899V22.9948H81.5259V28.0998C81.5259 29.8429 82.3145 30.5402 84.2984 30.5402C84.647 30.5402 84.9791 30.4987 85.2696 30.4489V28.9631C85.0206 28.988 84.8628 29.0046 84.5889 29.0046C83.7007 29.0046 83.3106 28.5895 83.3106 27.6432V22.9948H85.2696V21.4924H83.3106V19.2013H81.5259Z" fill="black"></path>
        <path d="M90.3234 30.6647C92.9631 30.6647 94.5817 28.8967 94.5817 25.9665C94.5817 23.0529 92.9548 21.2765 90.3234 21.2765C87.6838 21.2765 86.0568 23.0529 86.0568 25.9665C86.0568 28.8967 87.6755 30.6647 90.3234 30.6647ZM90.3234 29.0793C88.7712 29.0793 87.8996 27.9421 87.8996 25.9665C87.8996 24.0075 88.7712 22.862 90.3234 22.862C91.8674 22.862 92.7473 24.0075 92.7473 25.9665C92.7473 27.9338 91.8674 29.0793 90.3234 29.0793Z" fill="black"></path>
        <path d="M95.9666 30.4904H97.7513V25.153C97.7513 23.883 98.7059 23.028 100.059 23.028C100.374 23.028 100.906 23.0861 101.055 23.1359V21.3762C100.864 21.3263 100.524 21.3014 100.258 21.3014C99.0794 21.3014 98.075 21.9489 97.8177 22.8371H97.6849V21.4509H95.9666V30.4904Z" fill="black"></path>
        <path d="M105.486 22.7956C106.806 22.7956 107.669 23.717 107.711 25.1364H103.145C103.245 23.7253 104.166 22.7956 105.486 22.7956ZM107.703 28.05C107.371 28.7555 106.632 29.1457 105.553 29.1457C104.125 29.1457 103.204 28.1413 103.145 26.5558V26.4562H109.529V25.8337C109.529 22.9948 108.01 21.2765 105.495 21.2765C102.946 21.2765 101.328 23.111 101.328 25.9997C101.328 28.8884 102.913 30.6647 105.503 30.6647C107.57 30.6647 109.014 29.6686 109.421 28.05H107.703Z" fill="black"></path>
        <path d="M69.8223 27.1522C69.96 29.372 71.8098 30.7915 74.5629 30.7915C77.5053 30.7915 79.3464 29.3031 79.3464 26.9286C79.3464 25.0616 78.2968 24.0292 75.7502 23.4355L74.3822 23.1C72.7647 22.7214 72.1109 22.2138 72.1109 21.3277C72.1109 20.2092 73.1261 19.4779 74.6489 19.4779C76.0943 19.4779 77.0923 20.192 77.273 21.3363H79.1486C79.0367 19.2456 77.1956 17.7744 74.6747 17.7744C71.9646 17.7744 70.1579 19.2456 70.1579 21.4567C70.1579 23.2807 71.1817 24.3647 73.4272 24.8895L75.0275 25.2767C76.6707 25.6638 77.3934 26.2317 77.3934 27.1781C77.3934 28.2793 76.2578 29.0794 74.7091 29.0794C73.0487 29.0794 71.8958 28.3309 71.7323 27.1522H69.8223Z" fill="black"></path>
        <path d="M51.335 21.3014C50.1065 21.3014 49.044 21.9157 48.4961 22.945H48.3633V21.4509H46.6451V33.4953H48.4297V29.1208H48.5708C49.044 30.0754 50.065 30.6398 51.3516 30.6398C53.6343 30.6398 55.087 28.8386 55.087 25.9665C55.087 23.0944 53.6343 21.3014 51.335 21.3014ZM50.8287 29.0378C49.3345 29.0378 48.3965 27.8591 48.3965 25.9748C48.3965 24.0822 49.3345 22.9035 50.837 22.9035C52.3477 22.9035 53.2525 24.0573 53.2525 25.9665C53.2525 27.884 52.3477 29.0378 50.8287 29.0378Z" fill="black"></path>
        <path d="M61.3318 21.3014C60.1033 21.3014 59.0408 21.9157 58.4929 22.945H58.3601V21.4509H56.6419V33.4953H58.4265V29.1208H58.5676C59.0408 30.0754 60.0618 30.6398 61.3484 30.6398C63.6311 30.6398 65.0838 28.8386 65.0838 25.9665C65.0838 23.0944 63.6311 21.3014 61.3318 21.3014ZM60.8255 29.0378C59.3313 29.0378 58.3933 27.8591 58.3933 25.9748C58.3933 24.0822 59.3313 22.9035 60.8338 22.9035C62.3445 22.9035 63.2493 24.0573 63.2493 25.9665C63.2493 27.884 62.3445 29.0378 60.8255 29.0378Z" fill="black"></path>
        <path d="M43.4431 30.4904H45.4907L41.0083 18.0755H38.9348L34.4524 30.4904H36.4312L37.5755 27.1953H42.3074L43.4431 30.4904ZM39.8726 20.3297H40.0189L41.817 25.5778H38.0659L39.8726 20.3297Z" fill="black"></path>
        <path d="M35.6511 8.71069V14.6997H37.8135C39.5981 14.6997 40.6316 13.5999 40.6316 11.6865C40.6316 9.80225 39.5898 8.71069 37.8135 8.71069H35.6511ZM36.5808 9.55737H37.7097C38.9507 9.55737 39.6853 10.3459 39.6853 11.699C39.6853 13.0728 38.9631 13.853 37.7097 13.853H36.5808V9.55737Z" fill="black"></path>
        <path d="M43.7967 14.7869C45.1165 14.7869 45.9258 13.9028 45.9258 12.4377C45.9258 10.981 45.1124 10.0928 43.7967 10.0928C42.4769 10.0928 41.6634 10.981 41.6634 12.4377C41.6634 13.9028 42.4727 14.7869 43.7967 14.7869ZM43.7967 13.9941C43.0206 13.9941 42.5848 13.4255 42.5848 12.4377C42.5848 11.4583 43.0206 10.8855 43.7967 10.8855C44.5687 10.8855 45.0086 11.4583 45.0086 12.4377C45.0086 13.4214 44.5687 13.9941 43.7967 13.9941Z" fill="black"></path>
        <path d="M52.818 10.1799H51.9256L51.1205 13.6289H51.0499L50.1202 10.1799H49.2652L48.3355 13.6289H48.2691L47.4598 10.1799H46.555L47.8001 14.6997H48.7174L49.6471 11.3711H49.7176L50.6515 14.6997H51.577L52.818 10.1799Z" fill="black"></path>
        <path d="M53.8456 14.6997H54.7379V12.0559C54.7379 11.3503 55.1571 10.9104 55.817 10.9104C56.477 10.9104 56.7924 11.2715 56.7924 11.9978V14.6997H57.6847V11.7737C57.6847 10.6987 57.1286 10.0928 56.12 10.0928C55.4394 10.0928 54.9911 10.3958 54.7711 10.8979H54.7047V10.1799H53.8456V14.6997Z" fill="black"></path>
        <path d="M59.09 14.6997H59.9824V8.41602H59.09V14.6997Z" fill="black"></path>
        <path d="M63.3384 14.7869C64.6582 14.7869 65.4675 13.9028 65.4675 12.4377C65.4675 10.981 64.6541 10.0928 63.3384 10.0928C62.0186 10.0928 61.2051 10.981 61.2051 12.4377C61.2051 13.9028 62.0144 14.7869 63.3384 14.7869ZM63.3384 13.9941C62.5623 13.9941 62.1265 13.4255 62.1265 12.4377C62.1265 11.4583 62.5623 10.8855 63.3384 10.8855C64.1104 10.8855 64.5503 11.4583 64.5503 12.4377C64.5503 13.4214 64.1104 13.9941 63.3384 13.9941Z" fill="black"></path>
        <path d="M68.1263 14.0232C67.6407 14.0232 67.2879 13.7866 67.2879 13.3799C67.2879 12.9814 67.5701 12.7698 68.1927 12.7283L69.2967 12.6577V13.0354C69.2967 13.5957 68.7986 14.0232 68.1263 14.0232ZM67.898 14.7744C68.4915 14.7744 68.9854 14.5171 69.2552 14.0647H69.3257V14.6997H70.1849V11.6118C70.1849 10.6572 69.5457 10.0928 68.4126 10.0928C67.3875 10.0928 66.657 10.5908 66.5657 11.3669H67.429C67.5286 11.0474 67.8731 10.8647 68.3711 10.8647C68.9812 10.8647 69.2967 11.1345 69.2967 11.6118V12.002L68.0723 12.0725C66.9974 12.1389 66.3914 12.6079 66.3914 13.4214C66.3914 14.2473 67.0264 14.7744 67.898 14.7744Z" fill="black"></path>
        <path d="M73.213 14.7744C73.8355 14.7744 74.3626 14.4797 74.6324 13.9858H74.703V14.6997H75.558V8.41602H74.6656V10.8979H74.5992C74.3543 10.3999 73.8314 10.1052 73.213 10.1052C72.0716 10.1052 71.337 11.01 71.337 12.4377C71.337 13.8696 72.0633 14.7744 73.213 14.7744ZM73.4662 10.9062C74.2132 10.9062 74.6822 11.4998 74.6822 12.4419C74.6822 13.3882 74.2174 13.9734 73.4662 13.9734C72.7108 13.9734 72.2584 13.3965 72.2584 12.4377C72.2584 11.4873 72.7149 10.9062 73.4662 10.9062Z" fill="black"></path>
        <path d="M81.3444 14.7869C82.6643 14.7869 83.4736 13.9028 83.4736 12.4377C83.4736 10.981 82.6601 10.0928 81.3444 10.0928C80.0246 10.0928 79.2111 10.981 79.2111 12.4377C79.2111 13.9028 80.0205 14.7869 81.3444 14.7869ZM81.3444 13.9941C80.5683 13.9941 80.1325 13.4255 80.1325 12.4377C80.1325 11.4583 80.5683 10.8855 81.3444 10.8855C82.1164 10.8855 82.5563 11.4583 82.5563 12.4377C82.5563 13.4214 82.1164 13.9941 81.3444 13.9941Z" fill="black"></path>
        <path d="M84.6548 14.6997H85.5471V12.0559C85.5471 11.3503 85.9663 10.9104 86.6262 10.9104C87.2861 10.9104 87.6016 11.2715 87.6016 11.9978V14.6997H88.4939V11.7737C88.4939 10.6987 87.9377 10.0928 86.9292 10.0928C86.2485 10.0928 85.8003 10.3958 85.5803 10.8979H85.5139V10.1799H84.6548V14.6997Z" fill="black"></path>
        <path d="M92.6036 9.05518V10.2007H91.8856V10.9519H92.6036V13.5044C92.6036 14.376 92.9979 14.7246 93.9898 14.7246C94.1642 14.7246 94.3302 14.7039 94.4754 14.679V13.936C94.3509 13.9485 94.2721 13.9568 94.1351 13.9568C93.691 13.9568 93.4959 13.7493 93.4959 13.2761V10.9519H94.4754V10.2007H93.4959V9.05518H92.6036Z" fill="black"></path>
        <path d="M95.6732 14.6997H96.5656V12.0601C96.5656 11.3752 96.9723 10.9146 97.7028 10.9146C98.3336 10.9146 98.6698 11.2798 98.6698 12.002V14.6997H99.5622V11.782C99.5622 10.707 98.9687 10.0969 98.0058 10.0969C97.3251 10.0969 96.8478 10.3999 96.6278 10.9062H96.5573V8.41602H95.6732V14.6997Z" fill="black"></path>
        <path d="M102.781 10.8523C103.441 10.8523 103.873 11.313 103.894 12.0227H101.611C101.661 11.3171 102.121 10.8523 102.781 10.8523ZM103.889 13.4795C103.723 13.8323 103.354 14.0273 102.814 14.0273C102.101 14.0273 101.64 13.5251 101.611 12.7324V12.6826H104.802V12.3713C104.802 10.9519 104.043 10.0928 102.785 10.0928C101.511 10.0928 100.702 11.01 100.702 12.4543C100.702 13.8987 101.495 14.7869 102.789 14.7869C103.823 14.7869 104.545 14.2888 104.748 13.4795H103.889Z" fill="black"></path>
        <path d="M24.769 20.3011C24.7907 18.6201 25.6934 17.0295 27.1256 16.149C26.2221 14.8587 24.7088 14.0406 23.1344 13.9913C21.4552 13.8151 19.8272 14.9962 18.9715 14.9962C18.0992 14.9962 16.7817 14.0088 15.363 14.038C13.5137 14.0978 11.7898 15.1491 10.8901 16.7659C8.95607 20.1143 10.3987 25.0354 12.2513 27.742C13.1782 29.0673 14.2615 30.5478 15.6789 30.4953C17.066 30.4377 17.584 29.6108 19.2583 29.6108C20.9171 29.6108 21.4031 30.4953 22.8493 30.4619C24.3377 30.4377 25.2754 29.1306 26.1698 27.7927C26.8358 26.8484 27.3483 25.8047 27.6882 24.7002C25.9391 23.9604 24.771 22.2002 24.769 20.3011Z" fill="black"></path>
        <path d="M22.0373 12.2113C22.8489 11.2371 23.2487 9.98494 23.1518 8.7207C21.912 8.85092 20.7668 9.44348 19.9443 10.3803C19.14 11.2956 18.7214 12.5258 18.8006 13.7417C20.0408 13.7545 21.2601 13.178 22.0373 12.2113Z" fill="black"></path>
        <rect x="0.5" y="0.5" width="134" height="39" rx="4.5" stroke="black"></rect>
    </svg>
</template>